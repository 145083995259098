/*============================================================== 
 For all pages 
 ============================================================== */

/*******************
 Input not selected the content
*******************/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/*******************
 Main container
*******************/

.page-breadcrumb {
  background: $white;
}

.main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mat-drawer-container {
  background: $bodycolor;
}

html .page-wrapper {
  // height: calc(100vh - 64px);
  height: 100vh;

  .page-content {
    padding: 15px;
  }
}

/*******************
 Login register
*******************/
.login-register {
  height: 100vh;
  display: flex;
  background-size: cover;
  align-items: center;

  .mat-card {
    margin: 0px 15px;
  }

  .mat-form-field {
    width: 100%;
  }

  .login-register-box {
    max-width: 400px;
    min-width: 280px;
    margin: 0 auto;
    vertical-align: middle;
  }

  .support-text {
    margin-top: -15px;
    display: block;
    padding-bottom: 15px;
  }
}

.error-card {
  h1 {
    font-size: 210px;
    font-weight: 900;
    line-height: 210px;
    margin: 0px;
    text-shadow: 4px 5px 1px #b3bbbf;
  }
}

.ngx-datatable.material {
  box-shadow: none;
  border: 1px solid $border;
}

/*******************
 Calendar page
*******************/
.cal-event-action {
  text-decoration: none;
  margin: 0 3px;
}

.cal-header {
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.act {
  color: white;

  &:hover {
    color: aqua;
  }
}

/*******************
 Mail page
*******************/
.icon-box {
  width: 20%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/*******************
 Mail page
*******************/
.email-box {
  border: 1px solid $border;
  height: calc(100vh - 180px);
}

.email-sidebar {
  .mat-drawer-inner-container {
    overflow: visible;
  }

  border-right: 1px solid $border;
  width: 300px;

  .d-flex {
    display: flex;
    padding: 15px 0px;
    cursor: pointer;
  }

  .selected {
    .d-flex {
      background: $light;
    }
  }

  .icon-avatar {
    width: 60px;
    text-align: center;
    flex-shrink: 0;
  }

  .mail-title {
    font-weight: 500;
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    max-width: 165px;
    text-overflow: ellipsis;
  }

  .avatar {
    width: 40px;
    border-radius: 100%;
  }
}

.email-right-panel {
  padding: 30px;
  background: $light;

  .avatar-lg {
    width: 100px;
    border-radius: 100%;
  }

  .row {
    overflow: hidden;
  }

  .col-md-2 {
    width: 20%;
    padding: 10px;
    float: $lft;
  }
}

.align-items-center {
  align-items: center;
}

.bottom-right {
  position: absolute !important;
  right: 40px;
  bottom: 40px;
  z-index: 10;
}

.chat-right-panel {
  .top-avatar {
    img {
      width: 40px;
      vertical-align: middle;
    }
  }
}

.chat-middle-box {
  border-left: 1px solid $border;
  background: $white;
  margin-bottom: 0px !important;
}

.chat-footer {
  padding: 10px 15px !important;
  background: $white;
  border-top: 1px solid $border;
  border-left: 1px solid $border;
}

.chat-list {
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1rem;
  //padding: 0 2rem;
  display: flex;

  &.even {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -moz-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right;
  }
}

.chat-block {
  display: flex;
  align-items: center;
  padding: 15px;
  background: $light;
  border-radius: 6px;

  img {
    vertical-align: middle;
    margin-right: 10px;
  }
}

.chat-date {
  font-size: 12px;
  color: $muted;
}

/*******************
Chartistt chart css
******************/
.barchrt {
  .ct-series-a .ct-bar {
    stroke: $success;
  }

  .ct-series-b .ct-bar {
    stroke: $info;
  }
}

.linearea {
  height: 280px;

  .ct-series-a .ct-area {
    fill-opacity: 0.05;
    fill: $success;
  }

  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: $success;
    stroke-width: 2px;
  }

  .ct-series-b .ct-area {
    fill: $info;
    fill-opacity: 0.1;
  }

  .ct-series-b .ct-line,
  .ct-series-b .ct-point {
    stroke: $info;
    stroke-width: 2px;
  }

  .ct-series-c .ct-area {
    fill: $danger;
    fill-opacity: 0.1;
  }

  .ct-series-c .ct-line,
  .ct-series-c .ct-point {
    stroke: $danger;
    stroke-width: 2px;
  }

  .ct-series-a .ct-point,
  .ct-series-b .ct-point,
  .ct-series-c .ct-point {
    stroke-width: 6px;
  }
}

.piechart {
  .ct-series-a .ct-slice-donut {
    stroke: $info;
  }

  .ct-series-b .ct-slice-donut {
    stroke: $success;
  }

  .ct-series-c .ct-slice-donut {
    stroke: $purple;
  }

  .ct-series-d .ct-slice-donut {
    stroke: $light;
  }

  .ct-series-e .ct-slice-donut {
    stroke: $warning;
  }
}

/*******************
Pricing scss
******************/
html {
  .popular-plan {
    transform: scale(1.1);
    z-index: 1;
  }

  .price-listing {
    margin-bottom: 30px;

    .mat-list-item {
      height: auto;

      .mat-list-item-content {
        display: block;
        padding: 20px 0;
      }
    }
  }
}

/*******************
Chartis tooltip scss
******************/
.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 50px;
  padding: 5px 10px;
  border-radius: 5px;
  background: #313131;
  color: $white;
  font-weight: 500;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

/*.chartist-tooltip:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: #313131; }
*/
.chartist-tooltip.tooltip-show {
  opacity: 1;
}

.ct-area,
.ct-line {
  pointer-events: none;
}

/*# sourceMappingURL=chartist-plugin-tooltip.css.map */
.no-wrap {
  td,
  .ng-star-inserted {
    white-space: nowrap;
  }
}

/*******************
Smart table
******************/
.smart-table tr td,
.smart-table tr th {
  padding: 15px !important;
  text-align: left;
}

.ng2-smart-action-add-add {
  color: $white !important;
  background: $themecolor;
  padding: 8px 15px;
  border-radius: $radius;
  white-space: nowrap;
}

.ng2-smart-pagination-nav {
  margin-left: auto;
}

.ng2-smart-pagination-nav .pagination > li > a {
  line-height: 1rem;
}

.ng2-smart-filter .form-control {
  border: 1px solid $border;
  border-radius: 0.25rem;
  min-height: 38px;
}

.form-control {
  border: 1px solid $border;
}

/**********************
Overall Responsive table
***********************/

.responsive-table {
  display: flex;
  flex-direction: column;
  overflow: auto;

  .mat-cell:first-of-type,
  .mat-footer-cell:first-of-type,
  .mat-header-cell:first-of-type {
    padding-left: 0;
  }

  .header-label {
    display: none;
  }

  .mat-table {
    overflow: auto;
    max-height: 100%;
  }
}

.table-responsive {
  overflow: auto;
}

.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: $themecolor;
  background-color: $white;
  border: 1px solid $border;
}

.page-link:hover {
  z-index: 2;
  color: $bodytext;
  text-decoration: none;
  background-color: $light;
  border-color: $border;
}

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 1;
  color: $white;
  background-color: $themecolor;
  border-color: $themecolor;
}

.page-item.disabled .page-link {
  color: $muted;
  pointer-events: none;
  cursor: auto;
  background-color: $white;
  border-color: $border;
}

.ticket-list {
  td.mat-cell,
  td.mat-footer-cell,
  th.mat-header-cell {
    padding: 0 15px !important;
  }
}

.employee-list {
  td.mat-cell,
  td.mat-footer-cell,
  th.mat-header-cell {
    padding: 15px !important;
  }

  .d-flex {
    align-items: center;
  }
}

.chat-app {
  .mat-list-item {
    &.active {
      background: rgba(0, 0, 0, 0.04);
    }
  }
}

.notes-app {
  .mat-list-base .mat-list-item .mat-list-item-content,
  .mat-list-base .mat-list-option .mat-list-item-content {
    padding: 0 !important;
  }

  .mat-drawer-content {
    height: calc(100vh - 190px) !important;
  }

  &.mat-card .mat-card-content {
    padding: 0;
    height: calc(100% - 64px);
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    top: 33px !important;
    font-size: 14px !important;
  }
}

.notes-color-panel {
  min-width: 96px !important;

  .mat-menu-content {
    display: flex;

    .mat-menu-item {
      padding: 0 8px !important;
    }

    .mat-flat-button {
      min-width: 20px !important;
      padding: 0 !important;
    }
  }
}

.course-app {
  .course-header {
    .mat-card-header-text {
      width: 100%;
    }
  }

  .mat-card-content {
    margin-bottom: 0 !important;
  }
}

.banking {
  color: #000000;

  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }

  .sub-title {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #7A7A7A;
  }

  .button-banking {
    width: 222px;
    height: 40px;
    border: 1px solid #cccccc;
    border-radius: 200px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
  }

  .icon {
    color: $primary300;
    margin-right: 7px;
  }
}

.payment-pix {
  padding: 0px 30px;

  .mainTitle {
    font-weight: 900;
    font-size: 36px;
    margin: 15px 0 50px 0px;
  }

  .beneficiary {
    .details {
      div {
        padding: 10px 0px;
      }

      .data {
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .valueTransaction {
    .mat-hint {
      font-size: 12px;
      padding-left: 15px;
    }
    .mat-form-field-appearance-outline .mat-form-field-prefix {
      top: -4px !important;
      margin-right: 5px !important;
    }
    .detail {
      font-weight: 700;
      font-size: 16px;
    }
  }

  .anotherInf {
    .detail {
      font-weight: 700;
      font-size: 16px;
    }
  }

  .debtor-inf {
    .details {
      div {
        padding: 10px 0px;
      }

      .data {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  .transaction-inf {
    .details {
      div {
        padding: 10px 0px;
      }
    }
    .data {
      font-weight: 700;
      font-size: 16px;
    }

    .total {
      font-size: 16px;
    }

    .divisor {
      border: 1px solid #7a7a7a;
      width: 100%;
      height: 1px;
      margin: 10px 0px;
    }
  }

  .aditional-inf {
    .details {
      div {
        padding: 10px 0px;
      }

      .data {
        font-weight: 700;
        font-size: 16px;
      }
    }
  }

  .successPayment {
    img {
      width: 300px;
    }
    .title {
      text-align: center;
      font-size: 24px;
      font-weight: 700;
    }

    .detail {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.pix-charge {
  @extend .payment-pix;
}
// PIX ******************************************

.admin-home {
  padding: 40px 50px;
  .title {
    font-weight: 900;
    font-size: 36px;
    line-height: 40px;
    color: #000000;
  }
}

// PAYMENTS *************************************

.mat-step-header .mat-step-icon {
  color: #000000;
}

.mat-step-header .mat-step-icon-selected {
  background-color: #ffffff;
  border: 1px solid $primary300;
}

.mat-step-header .mat-step-icon-state-edit {
  background-color: $primary300;
}
// GENERAL ****************************************
.btnCancel {
  width: 100%;
  max-width: 286px;
  cursor: pointer;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #cccccc;
  font-weight: 700;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 260px;

  i {
    padding: 0px 5px;
  }
}

@media (max-width: 960px) {
  .btnCancel {
    height: 48px;
  }
}

.main-title {
  font-weight: 900;
  font-size: 36px;
}

.mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.disbledBtn {
  background-color: #cccccc !important;
}

.toolTip {
  display: block;
  transform: translateY(-50%);
  left: calc(50% - 20px);
  cursor: pointer;

  &:before {
    content: "";
    display: none;
    position: absolute;
    z-index: 9998;
    top: 35px;
    left: 15px;
    width: 0;
    height: 0;
  }

  &:after {
    content: "Copiar código";
    display: none;
    position: absolute;
    z-index: 9999;
    top: 40px;
    left: -37px;
    width: 114px;
    height: 36px;

    color: #fff;
    font-size: 10px;
    line-height: 36px;
    text-align: center;

    background: rgba(0, 0, 0, 0.72);
    border-radius: 3px;
  }

  &:hover {
    // background-color: #eee;

    &:before,
    &:after {
      display: block;
    }
  }
}

.toolTip.activeToolTip {
  outline: none;

  &:after {
    content: "Copiado !";
  }
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 11px 0px 15px 0px !important;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-700 {
  font-weight: 700;
}

.f-s-16 {
  font-size: 16px;
}

.f-s-20 {
  font-size: 20px;
}

.f-s-24 {
  font-size: 24px;
}

.wrap-word {
  overflow-wrap: break-word;
}

.badgeNumber {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: $primary300;
  color: #000000;
  text-align: center;
  font-size: 12px;
  top: -15px;
  z-index: 99;
  left: 129px;
  position: relative;
}
/* 
@media (min-width: 576) and (max-width: 991px) {
  .badgeNumber {
    top: -30px;
  }
} */

.btn-primary-filter {
  background-color: $primary300;
  border-radius: 200px;
  color: $overPrimary;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  width: 154px;
  height: 40px;
  border: none;

  .filter-icon {
    color: $primary300;
  }
}
// GENERAL ****************************************

// transfer ****************************************
.valuetransfer {
  .mat-form-field-prefix {
    margin-right: 4px !important;
    top: -1px !important;
  }
}

.table-transfer {
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  div:nth-of-type(even) {
    font-weight: bold;
  }

  .title {
    font-size: 20px;
    line-height: 24px;
  }
}

.transfer-buttons {
  height: 50px;
}

.payments {
  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0 !important;
  }

  .form-title {
    background: #f7f7f7;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  input {
    font-size: 16px;
  }

  .upload {
    cursor: pointer;
    height: 54px;
    border: 1px solid #cccccc;
    border-radius: 200px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    label {
      cursor: pointer;
    }

    i {
      font-size: 20px;
    }
  }

  .upload-minus {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 1px solid #cccccc;

    i {
      font-size: 20px;
    }
  }

  .upload-minus.active {
    background-color: $primary300;
    cursor: initial;
  }

  .upload.active {
    background-color: $primary300;
    cursor: initial;
  }
}
// transfer ****************************************

// ISSUE TICKET (EMITIR BOLETO)  ****************************************
.issue-ticket {
  margin-top: 50px;
  padding: 0px 30px;

  .mat-form-field-appearance-outline .mat-form-field-prefix {
    top: -1px !important;
    margin-right: 10px !important;
  }

  .payer {
    i {
      padding: 0px 5px;
    }
  }

  .title {
    font-weight: 700;
    font-size: 24px;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
  }

  .divisor {
    border: 1px solid #cccccc;
    height: 1px;
    margin: 20px 0px;
  }

  .data-ticket {
    background-color: #f7f7f7;
    border-radius: 8px;
    padding: 10px 15px 20px 15px;
    margin-top: 20px;

    .title-ticket {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
    }

    .info {
      margin: 7px 0px;
      font-weight: 400;
      font-size: 16px;
    }

    .response {
      margin: 7px 0px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .success {
    img {
      width: 60%;
    }
  }
}
// ISSUE TICKET (EMITIR BOLETO)  ****************************************

//PERFIL
.perfil {
  img {
    max-width: 100%;
    display: block;
  }

  .profile-photo {
    margin-right: 24px;
    width: 124px;
    height: 124px;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid $primary300;
  }
  .profile-photo-initial {
    background-color: $primary300;
    font-size: 40px;
    font-weight: bold;
    width: 124px;
    height: 124px;
    text-align: center;
    padding: 52px 0;

  }

  .logout-button {
    border: 1px solid #cccccc;
    background: #fff;
    color: #ec0505;
    padding: 8px 18px;
    border-radius: 200px;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;

    > i {
      font-size: 20px;
    }
  }

  .profile-data {
    .data-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      height: 24px;
      margin-bottom: 16px;
    }

    .data-info {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 8px;
      text-align: left;
    }

    .data-info div:nth-child(odd) {
      font-weight: normal;
    }

    .data-info div:nth-child(even) {
      font-weight: bold;
    }      

    .profile-code {
      width: 172px;
      height: 204px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      padding: 24px;

      .code-title {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #292929;
        text-align: center;
      }
    }
  
  }
}

// CHARGES SENDED **************************************************************
.charges-sended {
  padding: 0px 30px 20px 30px;

  // .mat-form-field-wrapper {
  //   padding-bottom: 1.25em !important;
  // }

  // table {
  //   width: 100%;

  //   th {
  //     background-color: #f7f7f7;
  //     color: #525252;
  //     height: 48px !important;
  //   }
  // }

  .search-field {
    .mat-form-field-wrapper {
      padding-bottom: 0px !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-prefix,
    .mat-form-field-appearance-outline .mat-form-field-suffix {
      top: -1px !important;
      margin-right: 10px;
    }
  }

  .mainTitle {
    font-weight: 900;
    font-size: 36px;
    padding-right: 20px;
  }

  .subTitle {
    font-size: 20px;
    font-weight: 700;
  }

  .resumes {
    margin-top: 20px;

    .itens {
      margin: 5px 0px;
      padding: 15px 20px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11), 0px 0px 2px rgba(0, 0, 0, 0.07);
      border-radius: 8px;

      .value {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }

  .table {
    margin-top: 40px;
  }

  .mainColor {
    color: $primary300;
  }

  .filter {
    height: 40px !important;
    cursor: pointer;
    border: 1px solid #cccccc;
    border-radius: 200px;
    padding: 15px 20px;
  }

  .showFilter {
    max-width: 837px;
    padding: 24px;
    border: 1px solid #cccccc;
    border-radius: 16px;
    box-shadow: 5px 10px 10px #cccccc;
    position: absolute;
    margin-top: 1em;
    z-index: 99;
    background-color: white;
    right: 54px;
  }

  .divisor {
    background-color: #cccccc;
    height: 250px;
    width: 1px;
    margin: 0 16px;
  }

  .period-type {
    border: 1px solid #cccccc;
    border-radius: 40px;
    width: 169px;
    height: 40px;
    padding: 4px;
    color: #7a7a7a;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      border-radius: 40px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    div.active {
      background-color: $primary50;
      color: $primary400;
    }
  }

  .mat-checkbox-frame {
    border-radius: 50% !important;
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    border-radius: 50% !important;
    background-color: $primary300 !important;
  }

  .mat-checkbox-checkmark .mat-checkbox-checkmark-path {
    stroke: $overPrimary !important;
  }

  .textPeriod {
    background-color: $primary50;
    border-radius: 8px;
    font-weight: 800;
  }

  .methodPadding {
    padding-left: 10px;
  }

  @media (min-width: 601px) and (max-width: 960px) {
    .methodPadding {
      padding-left: 0px;
    }

    .margin-top {
      margin-top: 20px;
    }

    .showFilter {
      width: 440px;
      right: 27px;
    }

    .divisor {
      display: none;
    }


  }

  .btn-primary-filter {
    cursor: pointer;
    background-color: $primary300;
    border-radius: 200px;
    color: $overPrimary;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    height: 40px;

    .filter-icon {
      color: $primary300;
    }
  }

  .hoverOnTableRow:hover {
    background-color: $primary50 !important;
    cursor: pointer;
  }

  .hoverOnTableRow-active {
    background-color: $primary50 !important;
    cursor: pointer !important;
  }
}

.charges-sended-showExtract {
  margin-top: -51px;
  padding: 24px;
  width: 300px !important;
  background-color: #f7f7f7;
  // overflow: scroll;
}

.charges-sended-showExtract::-webkit-scrollbar {
  width: 4px;
}

.charges-sended-showExtract::-webkit-scrollbar-track {
  background-color: $neutral50;
}

.charges-sended-showExtract::-webkit-scrollbar-thumb {
  background-color: $primary300;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid $neutral200; 
}

@media (min-width: 601px) and (max-width: 960px) {
  .charges-sended-showExtract {
    padding: 24px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    height: 100% !important;
    min-height: 100%;
    margin: 0;
    border-radius: 16px;
  }

  .toolTip {
    transform: translate(0);
  }

  .f-s-16 {
    font-size: 14px;
  }

  .f-s-14 {
    font-size: 12px;
  }
}

// CHARGES SENDED **************************************************************

// RECHARGES
.recharge {
  .details {
    div:nth-child(odd) {
      padding: 10px 0px;
    }

    div:nth-child(even) {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.sb-wrapper {
  height: 48px !important;
  width: 48px !important;
  border-radius: 50% !important;
  border: none;
  margin-right: 24px;
  cursor: pointer;
  color: #ffffff;
  font-size: 20px !important;
  line-height: 20px !important;
}

.sb-whatsapp {
  background: #65cf72 !important;
}

.sb-email {
  background: #7a7a7a !important;
}

.modal-session {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 98888;

  .content-modal-session {
    width: 300px;
    height: 200px;
    border-radius: 16px;
    padding: 24px;
    background-color: #ffffff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;
    color: #000000;
  }

  .atention {
    color: #ec0505;
    text-align: center;
  }

  .btn-session {
    width: 214px;
    background-color: $primary300;
    color: $overPrimary;
    font-weight: bold;
    margin: 24px auto 0 auto;
  }

  .time-session {
    color: #ec0505;
  }
}

.escrow {
  padding: 16px 30px;

  .escrow-info {
    margin-top: 16px;
    margin-bottom: 56px;
  }
}