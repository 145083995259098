@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');
@import "~@fortawesome/fontawesome-free/css/all.css";


$bodyFont: "Lato", normal;
$headlineFont: "Lato", normal;

html, body { height: 100%; }
body{
    font-family:'Lato' !important;
}
