// ***************** BOTÕES **********************

.btn {
  width: 100%;
  height: 40px;
  border-radius: 200px;
  cursor: pointer;
  border: 1px solid #cccccc;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  justify-content: center;
  background-color: #ffffff;
  max-width: 260px;

  i {
    margin-right: 12px;
    font-size: 20px;
    line-height: 20px;
  }
}

.btn-filter {
  width: 118px;
  justify-content: space-between;
  font-weight: 700;

  > i:first-child {
    color: $primary300;
  }
}

.btn-primary {
  background-color: $primary300;
  color: $overPrimary !important;
}

.btn-primary.disabled {
  background-color: $neutral100;
  color: #ffffff !important;
}

.fill-one {
  fill: $primary300;
}

.fill-two {
  fill: $primary300;
  background-color: $primary300 !important;
  color: black !important;
}

.btn-primary.disabled {
  background-color: $neutral100 !important;
  color: #ffffff !important;
  cursor: not-allowed !important;
}

.download-btn {
  width: 156px;
  height: 40px;
  border: 1px solid #cccccc;
  border-radius: 200px;
  padding: 8px 18px;
  cursor: pointer;

  i {
    font-size: 16px;
  }
}

.download-options {
  position: relative;
}
.options {
  padding: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  position: absolute;
  border: 1px solid #cccccc;
  z-index: 99;
  bottom: -124px;
  width: 160px;
  right: 0;

  div {
    padding: 0 16px;
    border-radius: 200px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
}

.options div:hover {
  border: 1px solid $primary300;
  font-weight: 700;
}

.filter-overlay {
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.logout-button {
  min-width: 94px;
  max-width: 120px;
  height: 40px;
  border: 1px solid #cccccc;
  background: #fff;
  color: #ec0505;
  padding: 8px 18px;
  border-radius: 200px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  > i {
    font-size: 20px;
  }
}

.search-field {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #525252;
  justify-content: space-between;
  border-radius: 8px;

  input {
    border: none;
    width: 100%;
    height: 24px;
    font-size: 16px;
    line-height: 24px;
  }

  i {
    font-size: 20px;
    line-height: 20px;
    color: #525252;
    cursor: pointer;
  }
}

.mat-expansion-panel-header-title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $neutral600;
  font-family: $bodyfont;
}

.mat-expansion-panel-header {
  background: $neutral50 !important;
}

.mat-expansion-panel-header.mat-expanded {
  height: 48px !important;
  margin-bottom: 16px;
}

.mat-expansion-panel-header-description {
  justify-content: flex-end;
}

.mat-radio-button {
  font-family: $bodyfont !important;
  font-size: 14px;
  line-height: 20px;
  color: $neutral600;
}

.mat-input-element {
  font-size: 16px !important;
  line-height: 24px !important;
  font-family: $bodyfont !important;
}

.mat-form-field-wrapper {
  padding-bottom: 1.25em !important;
}

.mat-form-field {
  height: 60px !important;
}

.mat-option-text {
  font-family: $bodyfont;
}

.mat-form-field-flex {
  display: flex;
  align-items: center;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $primary300;
}

.panel-contact {
  overflow: inherit !important;

  .mat-expansion-panel-body {
    padding: 0 !important;
  }

  .mat-expansion-panel-header.mat-expanded {
    margin-bottom: 0;
  }
}

// .mat-checkbox-checked.mat-accent .mat-checkbox-background {
// 	background-color: $primary300;
//   color: #000000 !important;
// }

@media (max-width: 960px) {
  .download-btn {
    width: 147px;
    height: 48px;
    padding: 12px 24px;
  }

  .btn {
    height: 48px;
    padding: 12px 24px;
  }

  .btn-filter {
    width: 142px;
  }
}
