/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import "variable";

@import "icons/themify-icons/themify-icons.css";
@import "icons/font-awesome/css/font-awesome.css";
@import "icons/flag-icon-css/flag-icon.min.css";
@import "spinner";
@import "header";
@import "widgets";

@import "horizontal.scss";

@import "pages";
@import "components";
@import "sidebar";
@import "app";
@import "dark-sidebar";
@import "responsive";
@import "rtl";

// Red Theme

.danger {
  @import "colors/red";
  @include angular-material-theme($red-app-theme);
}

// Blue Theme

.blue {
  @import "colors/blue";
  @include angular-material-theme($blue-app-theme);
}

// Green Theme

.green {
  @import "colors/green";
  @include angular-material-theme($green-app-theme);
}

// Dark Theme

.dark {
  @import "colors/dark";
  @include angular-material-theme($dark-theme);
}

$custom-typography: mat-typography-config(
  $font-family: "Lato",
);
@include mat-core($custom-typography);
