// Variables
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

$bodyfont: 'Lato', sans-serif;
$headingfont: 'Lato', sans-serif;

@import '~@angular/material/theming';

/*Material Theme Colors*/

$primaryM: mat-palette($mat-blue, 600);
$accent: mat-palette($mat-indigo, 500);
$warn: mat-palette($mat-pink, 500);
$theme: mat-light-theme($primaryM, $accent, $warn);
$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

// Default Theme
@include angular-material-theme($theme);

// Dark Theme

/*Theme Colors*/

$topbar: #2196f3;
$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt: #f2f6f8;
$bodycolor: white;
$headingtext: #455a64;
$bodytext: #67757c;
$sidebar-text: #000000;
$sidebar-icons: #99abb4;

$font-16: 16px;

$light-text: #a6b7bf;
$themecolor: #1e88e5;
$themecolor-alt: #fffcc9;
$themecolor-dark: #028ee1;

/*bootstrap Color*/
$danger: #fc4b6c;
$success: #26c6da;
$warning: #ffb22b;
$primaryM: #7460ee;
$info: #1e88e5;
$inverse: #2f3d4a;
$muted: #99abb4;
$dark: #263238;
$light: #f2f4f8;
$extra-light: #ebf3f5;
$secondary: #727b84;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdf8;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #e3f3fd;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

/*Normal Color*/
$white: #ffffff;
$red: #fb3a3a;
$yellow: #a0aec4;
$purple: #7460ee;
$blue: #02bec9;
$megna: #00897b;

/*Extra Variable*/
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$radius: 200px;

//Neutral
$neutral0: var(--neutral0, #ffffff);
$neutral50: var(--neutral50, #f7f7f7);
$neutral100: var(--neutral100, #cccccc);
$neutral200: var(--neutral200, #a3a3a3);
$neutral300: var(--neutral300, #7a7a7a);
$neutral400: var(--neutral400, #525252);
$neutral500: var(--neutral500, #292929);
$neutral550: var(--neutral550, #1b1b1b);
$neutral600: var(--neutral600, #000000);

//Primary
$primary50: var(--primary50, #c2ecff);
$primary100: var(--primary100, #85d9ff);
$primary200: var(--primary200, #49c6ff);
$primary300: var(--primary300, #0cb3ff);
$primary400: var(--primary400, #008ac8);
$primary500: var(--primary500, #005c86);
$primary600: var(--primary600, #002e43);
$overPrimary: var(--overPrimary, #ffffff);
$single_card_color: var(--primary300, #0cb3ff);

//Status
$success50: var(--success50, #dcfeea);
$success300: var(--success300, #0cba52);
$error50: var(--error50, #ffeaea);
$error300: var(--error300, #ec0505);
$alert300: var(--alert300, #fbc400);
